// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-gajurmukhi-herbal-js": () => import("./../../../src/pages/gajurmukhi-herbal.js" /* webpackChunkName: "component---src-pages-gajurmukhi-herbal-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kasthamandal-js": () => import("./../../../src/pages/kasthamandal.js" /* webpackChunkName: "component---src-pages-kasthamandal-js" */),
  "component---src-pages-mount-kattha-js": () => import("./../../../src/pages/mount-kattha.js" /* webpackChunkName: "component---src-pages-mount-kattha-js" */),
  "component---src-pages-mount-rosin-js": () => import("./../../../src/pages/mount-rosin.js" /* webpackChunkName: "component---src-pages-mount-rosin-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */)
}

